import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { Inter, Sriracha } from "next/font/google";
import mm from "@/public/mm.png";
import mmfairy from "@/public/mm-fairy.png";
import mmm from "@/public/mmm.png";
import mickey from "@/public/mickey.png";
import location from "@/public/location.png";
import balloons from "@/public/balloons.png";

import { CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import localFont from "next/font/local";
const disney = localFont({ src: "disney.ttf" });

const inter = Inter({ subsets: ["latin"] });
const sriracha = Sriracha({ subsets: ["latin"], weight: "400" });

import { useLocalStorage } from "usehooks-ts";
import { Switch, RadioGroup } from "@headlessui/react";

import { NextSeo } from "next-seo";

// const GoogleMapsAPIKey = "AIzaSyBzjG5N1kCnjwIM68eYUh4hAAUYQvd8iAU";

const defaultRSVP = {
  id: null,
  name: "",
  adults: 1,
  kids: 1,
  attending: false,
  meal1: "",
  meal2: "",
  meal3: "",
};

export default function Home() {
  const router = useRouter();

  // Set up local storage
  const [rsvp, setRSVP] = useLocalStorage("rsvp", defaultRSVP);

  const [rsvpStatus, setRSVPStatus] = React.useState(0);
  // 0 => load by id
  // 1 => load by name
  // 2 => create new record from query
  // 5 => loaded and ready

  const [status, setStatus] = React.useState("");

  // Load data from API if rsvp.id is set in local storage
  React.useEffect(() => {
    const readData = async () => {
      const data = await fetch("/api/checkRecordById", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rsvp),
      }).then((res) => res.json());
      console.log(data);
      const kid = router.query.kid;
      if (data && kid && data.name === kid) {
        setRSVP(data);
        setRSVPStatus(5);
      } else {
        setRSVP(defaultRSVP);
        setRSVPStatus(1);
      }
    };
    if (rsvp.id && rsvpStatus === 0) readData();
    else if (!rsvp.id && rsvpStatus === 0) setRSVPStatus(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rsvp, rsvpStatus]);

  // Load data from API by name if rsvp.id is not set in local storage
  React.useEffect(() => {
    const readData = async (kid) => {
      const data = await fetch("/api/checkRecordByName", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: kid }),
      }).then((res) => res.json());
      console.log(data);
      if (data) {
        setRSVP(data);
        setRSVPStatus(5);
      } else setRSVPStatus(2);
    };
    const createRsvp = async (kid) => {
      var oldRsvp = rsvp;
      oldRsvp.name = kid;
      setRSVP(oldRsvp);
      setRSVPStatus(5);
      console.log(rsvp);
    };
    const { kid } = router.query;
    console.log(kid);
    if (kid && rsvpStatus === 1) readData(kid);
    // else if (!kid && rsvpStatus === 1) setRSVPStatus(5);
    else if (kid && rsvpStatus === 2) createRsvp(kid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rsvp, rsvpStatus, router]);

  // Save data to API when rsvp changes and rsvpStatus is 5
  React.useEffect(() => {
    //validate data
    const validateData = () => {
      if (rsvp.name === "") return false;
      if (rsvp.attending === false) return true;
      if (rsvp.adults === 0 && rsvp.kids === 0) {
        setStatus("Select number of kids.");
        return false;
      }
      if (rsvp.adults > 0 && rsvp.kids === 0) {
        setStatus("Select number of kids.");
        return false;
      }
      if (rsvp.kids >= 1 && rsvp.meal1 === "") {
        setStatus("Select meal for kid 1.");
        return false;
      }
      if (rsvp.kids >= 2 && rsvp.meal2 === "") {
        setStatus("Select meal for kid 2.");
        return false;
      }
      if (rsvp.kids >= 3 && rsvp.meal3 === "") {
        setStatus("Select meal for kid 3.");
        return false;
      }
      return true;
    };
    const saveData = async () => {
      const data = await fetch("/api/saveRecord", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rsvp),
      }).then((res) => res.json());
      console.log(data);
      console.log(rsvp);
      if (JSON.stringify(data) !== JSON.stringify(rsvp)) setRSVP(data);
      else setStatus("Saved!");
    };
    if (rsvpStatus === 5 && validateData()) {
      setStatus("Saving...");
      saveData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rsvp, rsvpStatus]);

  return (
    <main
      className={`flex min-h-screen max-w-lg mx-auto flex-col items-center box-border overflow-x-hidden justify-between text-pink-950/80 ${inter.className} `}
    >
      <NextSeo
        title="Maya's 3rd Birthday"
        description="We're celebrating with a Minnie Mouse themed party and you're invited!"
        canonical="https://maya.salama.ws/"
        openGraph={{
          url: "https://maya.salama.ws/",
          title: "Maya's 3rd Birthday",
          description:
            "We're celebrating with a Minnie Mouse themed party and you're invited! Tap to know more and RSVP.",
          images: [
            {
              url: "https://maya.salama.ws/og.png",
              width: 1200,
              height: 630,
              alt: "Maya's 3rd Birthday",
            },
          ],
          site_name: "Maya's 3rd Birthday",
        }}
      />
      <section className="container relative p-4 bg-gradient-to-b from-pink-500 to-pink-700 text-white/90 flex flex-col items-center">
        <Image
          className="w-3/4 max-w-screen-md z-10 relative"
          src={mm}
          alt="Minnie Mouse"
          priority
        />
        <Image
          className="absolute top-[60vw] sm:top-[330px] right-[20px] w-[35vw] sm:w-[150px] z-10 move-up-and-down"
          src={balloons}
          alt=""
        />
        <h1 className={`text-5xl text-center ${disney.className}`}>
          <span className="text-7xl">Maya</span>
          {" is turning "}
          <span className="text-7xl">3!</span>
        </h1>
        <h2 className={`text-2xl pt-4 text-center ${sriracha.className}`}>
          We&apos;re celebrating with a Minnie Mouse themed party and
          you&apos;re invited!
        </h2>
        <Image
          className="absolute top-[20px] left-[20px] opacity-10 move-diagonally"
          src={mickey}
          alt=""
          width={70}
          priority
        />
        <Image
          className="absolute top-[330px] left-[0px] opacity-10 move-diagonally"
          src={mickey}
          alt=""
          width={80}
          priority
        />
        <Image
          className="absolute top-[100px] right-[40px] opacity-10 move-diagonally"
          src={mickey}
          alt=""
          width={80}
          priority
        />
        <Image
          className="absolute bottom-[220px] right-[20px] opacity-10 move-diagonally"
          src={mickey}
          alt=""
          width={100}
          priority
        />
      </section>

      <section className="container p-4">
        <h3 className={`text-5xl text-pink-700 ${sriracha.className}`}>
          Save the date!
        </h3>
        <p className="text-xl">
          <span className="font-bold">Friday, October 6th</span> from{" "}
          <span className="font-bold">3:00pm</span> to{" "}
          <span className="font-bold">6:00pm</span>.
        </p>
        <button
          className="bg-pink-700 active:bg-pink-500 text-white/90 p-2 rounded-lg mt-4 w-full"
          onClick={() => {
            const link = document.createElement("a");
            link.href = `/calendar.ics`;
            link.download = "maya-birthday.ics";
            link.click();
          }}
        >
          Add to Calendar
        </button>
      </section>

      <section className="container p-4">
        <h3 className={`text-5xl text-pink-700 ${sriracha.className}`}>
          Location
        </h3>
        <Image
          className="float-right p-2"
          src={location}
          alt="Location"
          width={80}
          priority
        />
        <p className="text-xl font-bold">Funderdome, Mirqab Mall</p>
        <p className="pb-2">
          1st Floor, Mirqab Mall
          <br />
          Al Mirqab Al Jadeed Street
          <br />
          Al Nasr, Doha
        </p>
        <iframe
          width="100%"
          height="350"
          style={{ border: 0 }}
          referrerPolicy="no-referrer-when-downgrade"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBzjG5N1kCnjwIM68eYUh4hAAUYQvd8iAU&q=place_id:ChIJP9lZe9DbRT4RoGmkIF-4cks&zoom=15"
          allowFullScreen
        />
      </section>

      <section className="container p-4">
        <h3 className={`text-5xl pb-1 text-pink-700 ${sriracha.className}`}>
          Program
        </h3>
        <Image
          className="float-right"
          src={mmfairy}
          alt="Minnie Mouse Fairy"
          width={125}
          priority
        />
        <p className="">
          <span className="font-bold">3:00pm</span> - Soft Play
        </p>
        <p className="">
          <span className="font-bold">4:00pm</span> - Face Painting
        </p>
        <p className="">
          <span className="font-bold">5:00pm</span> - Hosted Games
        </p>
        <p className="">
          <span className="font-bold">5:30pm</span> - Birthday Cake
        </p>
        <p className="">
          <span className="font-bold">6:00pm</span> - Gifts
        </p>
      </section>

      <section className="container p-4">
        <h3 className={`text-5xl text-pink-700 ${sriracha.className}`}>
          Dress Theme
        </h3>
        <Image
          src={mmm}
          alt="Mickey Mouse"
          width="90%"
          priority
          className="py-4"
        />
        <div className="grid grid-cols-2 gap-x-4 text-center">
          <p className="font-bold">Girls:</p>
          <p className="font-bold">Boys:</p>
          <p>Pink, Black, White</p>
          <p>Red, Black, White</p>
        </div>
      </section>

      {rsvpStatus === 5 && (
        <section className="container p-4">
          <h3 className={`text-5xl text-pink-700 ${sriracha.className}`}>
            RSVP
          </h3>
          <p className="">
            Please RSVP by{" "}
            <span className="font-bold">Wednesday, October 4th</span>. You can
            come back and make changes until then. All fields are required.
          </p>
          <p className="text-xl py-2">
            <span className="font-bold pr-4">Name</span>
            {rsvp.name}
          </p>
          <p className="text-xl font-bold py-2">
            Attending
            <span>
              <Switch
                checked={rsvp.attending}
                onChange={(value) => {
                  var oldRsvp = rsvp;
                  oldRsvp.attending = value;
                  setRSVP(oldRsvp);
                }}
                className={`${
                  rsvp.attending ? "bg-pink-700" : "bg-pink-500"
                } relative inline-flex float-right items-center h-6 rounded-full w-11`}
              >
                <span className="sr-only">Attending?</span>
                <span
                  className={`${
                    rsvp.attending ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform bg-white rounded-full`}
                />
              </Switch>
            </span>
          </p>
          {rsvp.attending && (
            <>
              <RadioGroup
                className={"py-2"}
                value={rsvp.adults}
                onChange={(value) => {
                  var oldRsvp = rsvp;
                  oldRsvp.adults = value;
                  setRSVP(oldRsvp);
                }}
              >
                <RadioGroup.Label className="text-xl font-bold">
                  Number of Adults
                </RadioGroup.Label>
                <div className="grid grid-cols-3 gap-x-4 text-center">
                  <RadioGroup.Option value={1}>
                    {({ checked }) => (
                      <p
                        className={`${
                          checked ? "bg-pink-700" : "bg-pink-500"
                        } text-white/90 p-2 rounded-lg cursor-pointer`}
                      >
                        1
                      </p>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option value={2}>
                    {({ checked }) => (
                      <p
                        className={`${
                          checked ? "bg-pink-700" : "bg-pink-500"
                        } text-white/90 p-2 rounded-lg cursor-pointer`}
                      >
                        2
                      </p>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option value={3}>
                    {({ checked }) => (
                      <p
                        className={`${
                          checked ? "bg-pink-700" : "bg-pink-500"
                        } text-white/90 p-2 rounded-lg cursor-pointer`}
                      >
                        3
                      </p>
                    )}
                  </RadioGroup.Option>
                </div>
              </RadioGroup>
              <RadioGroup
                className={"py-2"}
                value={rsvp.kids}
                onChange={(value) => {
                  var oldRsvp = rsvp;
                  oldRsvp.kids = value;
                  setRSVP(oldRsvp);
                }}
              >
                <RadioGroup.Label className="text-xl font-bold">
                  Number of Kids
                </RadioGroup.Label>
                <div className="grid grid-cols-3 gap-x-4 text-center">
                  <RadioGroup.Option value={1}>
                    {({ checked }) => (
                      <p
                        className={`${
                          checked ? "bg-pink-700" : "bg-pink-500"
                        } text-white/90 p-2 rounded-lg cursor-pointer`}
                      >
                        1
                      </p>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option value={2}>
                    {({ checked }) => (
                      <p
                        className={`${
                          checked ? "bg-pink-700" : "bg-pink-500"
                        } text-white/90 p-2 rounded-lg cursor-pointer`}
                      >
                        2
                      </p>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option value={3}>
                    {({ checked }) => (
                      <p
                        className={`${
                          checked ? "bg-pink-700" : "bg-pink-500"
                        } text-white/90 p-2 rounded-lg cursor-pointer`}
                      >
                        3
                      </p>
                    )}
                  </RadioGroup.Option>
                </div>
              </RadioGroup>
              {rsvp.kids >= 1 && (
                <p className="text-md">
                  We will serve a McDonalds Happy Meal to all kids. Please
                  choose the meal preference.
                </p>
              )}
              {rsvp.kids >= 1 && (
                <RadioGroup
                  className={"py-2"}
                  value={rsvp.meal1}
                  onChange={(value) => {
                    var oldRsvp = rsvp;
                    oldRsvp.meal1 = value;
                    setRSVP(oldRsvp);
                  }}
                >
                  <RadioGroup.Label className="text-xl font-bold">
                    Kid 1 Meal
                  </RadioGroup.Label>
                  <div className="grid grid-cols-3 gap-x-4 text-center">
                    <RadioGroup.Option value={"Chicken"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg cursor-pointer`}
                        >
                          Chicken
                        </p>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value={"Beef"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg cursor-pointer`}
                        >
                          Beef
                        </p>
                      )}
                    </RadioGroup.Option>
                    {/* <RadioGroup.Option value={"veg"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg`}
                        >
                          Veg
                        </p>
                      )}
                    </RadioGroup.Option> */}
                  </div>
                </RadioGroup>
              )}
              {rsvp.kids >= 2 && (
                <RadioGroup
                  className={"py-2"}
                  value={rsvp.meal2}
                  onChange={(value) => {
                    var oldRsvp = rsvp;
                    oldRsvp.meal2 = value;
                    setRSVP(oldRsvp);
                  }}
                >
                  <RadioGroup.Label className="text-xl font-bold">
                    Kid 2 Meal
                  </RadioGroup.Label>
                  <div className="grid grid-cols-3 gap-x-4 text-center">
                    <RadioGroup.Option value={"Chicken"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg cursor-pointer`}
                        >
                          Chicken
                        </p>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value={"Beef"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg cursor-pointer`}
                        >
                          Beef
                        </p>
                      )}
                    </RadioGroup.Option>
                    {/* <RadioGroup.Option value={"veg"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg`}
                        >
                          Veg
                        </p>
                      )}
                    </RadioGroup.Option> */}
                  </div>
                </RadioGroup>
              )}
              {rsvp.kids >= 3 && (
                <RadioGroup
                  className={"py-2"}
                  value={rsvp.meal3}
                  onChange={(value) => {
                    var oldRsvp = rsvp;
                    oldRsvp.meal3 = value;
                    setRSVP(oldRsvp);
                  }}
                >
                  <RadioGroup.Label className="text-xl font-bold">
                    Kid 3 Meal
                  </RadioGroup.Label>
                  <div className="grid grid-cols-3 gap-x-4 text-center">
                    <RadioGroup.Option value={"Chicken"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg cursor-pointer`}
                        >
                          Chicken
                        </p>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value={"Beef"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg cursor-pointer`}
                        >
                          Beef
                        </p>
                      )}
                    </RadioGroup.Option>
                    {/* <RadioGroup.Option value={"veg"}>
                      {({ checked }) => (
                        <p
                          className={`${
                            checked ? "bg-pink-700" : "bg-pink-500"
                          } text-white/90 p-2 rounded-lg`}
                        >
                          Veg
                        </p>
                      )}
                    </RadioGroup.Option> */}
                  </div>
                </RadioGroup>
              )}
            </>
          )}
          <div className="h-16 flex items-center">
            <p
              className={` ${
                status === "Saved!"
                  ? "text-green-700"
                  : status === "Saving..."
                  ? "text-pink-950/80"
                  : "text-red-500 font-bold"
              }`}
            >
              <span>
                {status === "Saving..." && (
                  <span className="pr-2">
                    <CircularProgress size="1rem" />
                  </span>
                )}
              </span>
              <span>
                {status === "Saved!" && (
                  <span className="pr-2">
                    <CheckIcon size="1rem" />
                  </span>
                )}
              </span>
              {status}
            </p>
          </div>
        </section>
      )}

      <footer className="container p-4 bg-black text-white/80">
        <p className="text-center p-4">
          Follow Maya on Instagram{" "}
          <a
            href="https://www.instagram.com/maya.n.adam/"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            @maya.n.adam
          </a>
        </p>
        <p className="text-center p-4">
          Made with{" "}
          <span role="img" aria-label="love">
            ❤️
          </span>{" "}
          by Waleed Salama
        </p>
      </footer>
    </main>
  );
}
